<template lang="pug">
v-main(v-if='Inicio')
    Carrusel_imagenes_container
    Arquitectura_y_saneamiento_container
    Clientes_satisfechos_container
</template>
<script>
export default {
    data: ()=>({
        valor: 0,
    }),
    components: {
      Carrusel_imagenes_container: ()=>import('./componentes/carrusel_imágenes_container'),
      Arquitectura_y_saneamiento_container: ()=>import('./componentes/arquitectura_y_saneamiento_container'),
      Clientes_satisfechos_container: ()=>import('./componentes/clientes_satisfechos_container'),
    },
}
</script>